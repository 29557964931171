<template>

  <Datatable
      :api-endpoint="ENDPOINTS.DATATABLES.ERP.PRICETEMPLATES.ALL"
      :datatable-headers="this.datatableHeaders"
      :excel-columns="excelColumns"
      :v-show="true"
      @deleteEntries="deleteEntries"
      @deleteEntry="deleteEntry"
      @editEntry="editEntry"
      excel-file-name="pricetemplates"
      ref="pricetemplate"
      show-delete-buttons
      show-edit-buttons
      show-pdf
      show-select-checkbox
      :permissionDelete="this.$store.getters['permissions/checkPermission']('allowCreateEditPriceTMPL')"
      :permissionEdit="this.$store.getters['permissions/checkPermission']('allowCreateEditPriceTMPL')"
  />

</template>

<script>
import Datatable from "../../../datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "PriceTemplateListComponent",

  components: {Datatable},

  data: () => {
    return {
      ENDPOINTS,
    }
  },

  methods: {
    delete(idsToDelete) {
      this.$swal({
        title: this.$t("erp.lang_deletePriceTmplHead"),
        text: this.$t("erp.lang_deletePriceTmplBody"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.ERP.PRICETEMPLATES.DELETE, {
            priceTMPLID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t("erp.lang_priceTmplDeleted"),
                color: "success"
              });

              this.$refs.pricetemplate.getDataFromApi();
              this.$refs.pricetemplate.resetSelectedRows();
            } else if (res.data.status === 'ACTIVE') {

              this.$swal({
                title: this.$t('erp.lang_deletePriceTemplErrorHead'),
                text: this.$t('erp.lang_deletePriceTemplErrorBody'),
                icon: "warning",
              });
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
              });
            }
          }).catch((err) => {

            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message
            });
          })
        }
      });
    },
    deleteEntry(entry) {
      this.delete([entry.id])
    },
    deleteEntries(ids) {
      this.delete(ids);
    },
    editEntry(entry) {
      this.$router.push('/erp/baseData/priceTMPL/edit/' + entry.id)
    }
  },

  computed: {
    datatableHeaders: function () {
      return [
        {
          text: "ID",
          value: "id",
          sortable: false,
          hide: true
        },
        {
          text: this.$t('erp.lang_TemplateName'),
          value: "item_name",
          sortable: false
        },
      ];
    },
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id"
        },
        {
          label: this.$t('erp.lang_TemplateName'),
          field: "item_name"
        }
      ];
    }
  },
}
</script>