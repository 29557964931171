<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_priceTemplates') "
                :icon="icon"
                :subheading="$t('erp.lang_priceTemplates')"
                show-add-button
                :permissionAdd="this.$store.getters['permissions/checkPermission']('allowCreateEditPriceTMPL')"
        ></page-title>
        <div class="app-main__inner">
            <price-template-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import PriceTemplateComponent from "../../../../components/erp/baseData/pricetemplate/PriceTemplateComponent";

    export default {
        components: {
            PriceTemplateComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-cash icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>