<template>


    <div class="mb-3 card">

        <div>
            <v-tabs v-model="tab">
                <v-tab v-if="this.$store.getters['permissions/checkPermission']('allowCreateEditPriceTMPL')" key="priceTMPL">
                    {{$t('erp.lang_priceTemplates')}}
                </v-tab>
                <v-tab key="assign" v-if="this.$store.getters['permissions/checkPermission']('allowAssignPriceTMPL')">{{$t('erp.lang_assignPriceTempl')}}</v-tab>

                <v-tabs-items touchless v-model="tab">
                    <v-tab-item v-if="this.$store.getters['permissions/checkPermission']('allowCreateEditPriceTMPL')" key="priceTMPL">
                        <price-template-list-component />
                    </v-tab-item>
                    <v-tab-item key="assign" v-if="this.$store.getters['permissions/checkPermission']('allowAssignPriceTMPL')">
                        <price-template-asign-component />
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </div>
    </div>



</template>

<script>
    import PriceTemplateListComponent from "./PriceTemplateListComponent";
    import PriceTemplateAsignComponent from "./PriceTemplateAssignComponent";
    export default {
        name: "PriceTemplateComponent",
        components: {PriceTemplateAsignComponent, PriceTemplateListComponent},
        data: () => {
            return {
                tab: 0,
                tabs: 0,
            }
        }
    }
</script>

<style scoped>
.v-btn{
    text-transform: none !important;
}
</style>