<template>
    <v-container class="ma-0 pa-0" fluid>
            <v-card-title>
                <span class="headline">
                    {{this.$t('erp.lang_assignPriceTempl')}}
                </span>

                <v-spacer/>


                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">

                        <v-btn :disabled="loading_update" :loading="loading_update" @click="update" color="primary" icon
                               v-on="on">
                            <v-icon>save</v-icon>
                        </v-btn>
                    </template>
                    <span>{{this.$t('generic.lang_apply')}}</span>
                </v-tooltip>
            </v-card-title>

            <v-divider class="ma-0 pa-0"/>

            <v-card-text class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="ma-0 pa-0" cols="12">
                        <div class="card-header-tab card-header">
                            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                                <b-input-group width="100%">
                                    <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  :placeholder="$t('datatables.lang_search') + ' ' + $t('generic.lang_cashierID')"
                                                  @focus="showTouchKeyboard"
                                                  v-model="search"/>
                                </b-input-group>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="12">
                        <v-data-table
                                :headers="this.datatableHeaders"
                                :items="this.cashiers "
                                :items-per-page="5"
                                :loading="this.loading"
                                :search="this.search"
                        >
                            <template v-slot:item.tmplID="{ item }">
                                <v-row align="center" align-content="center" justify="center">
                                    <b-form-select :class="[$vuetify.theme.dark? 'dark-bg' : '']" :options="priceTMPL" :placeholder="$t('erp.lang_selectedTemplate')"
                                                   text-field="name" v-model="item.tmplID"
                                                   value-field="tmplID"></b-form-select>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>


        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../../config";
    import {mapState} from "vuex";
    import mixin from "../../../../mixins/KeyboardMixIns";
    //components
    import Datatable from "../../../datatable/Datatable";
    import {Events} from "../../../../plugins/events";

    export default {

        name: "PriceTemplateEditCompnent",
        mixins: [mixin],
        components: {
            Datatable
        },
        data: () => {
            return {
                ENDPOINTS,
                search: "",
                priceTMPL: null,
                cashiers: [],
                loading: false,
                loading_update: false,
                selected: {}
            }
        },
      mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                this.loading = true;
                this.axios.post(ENDPOINTS.ERP.PRICETEMPLATES.GET).then((res) => {
                    this.cashiers = res.data.priceTMPLActive;
                    this.priceTMPL = res.data.priceTMPL;

                    this.priceTMPL.push({
                        tmplID: 0,
                        name: this.$t('erp.lang_selectedTemplate')
                    });
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });

                }).finally(() => {
                    this.loading = false
                });
            },
            update() {
                this.loading_update = true;


                this.axios.post(ENDPOINTS.ERP.PRICETEMPLATES.UPDATE, {
                    selected: this.cashiers
                }).then((res) => {

                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            color: "success",
                            message: this.$t('erp.lang_itemSuccessfulEdited')
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            color: "error",
                            message: this.$t('generic.lang_errorOccurred')
                        });
                    }

                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });

                }).finally(() => {
                    this.loading_update = false
                });
            }
        },
        computed: {
            ...mapState([
                'touchkeyboard'
            ]),
            datatableHeaders: function () {
                return [
                    {
                        text: this.$t('generic.lang_cashierID'),
                        value: "cashierID",
                        sortable: true
                    },
                    {
                        text: this.$t('erp.lang_selectedTemplate'),
                        value: "tmplID",
                        align: "center",
                        sortable: false
                    },
                ];
            },
        },
    }
</script>

<style scoped>
    .card-header-title {
        width: 100% !important;
    }
</style>